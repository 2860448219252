// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  Layout,
  LinkedButton,
  MarkdownPs,
  PricingPlan,
  Section,
  SectionsPartners,
  SectionPricingFeatures,
  TextHeading,
  QuestionAnswer,
  SectionPricingSlider,
  SectionPricingEnterprise,
} from '../components';
import { Dimen } from '../constants';
import { unwrapConnection } from '../util';

import type { PagePricingQuery } from '../__generated__/PagePricingQuery';

type Props = $ReadOnly<{|
  data: PagePricingQuery,
|}>;

const plans = [
  {
    name: 'Starter',
    prices: null,
    color: '#fed181',
    description:
      'For hosts who want to streamline check-ins and boost guest experience',
    features: [
      'iCal powered multi-calendar',
      'Management App for Hosts',
      'Guest WebApp',
      'Integrated upsales platform',
      'Host services marketplace',
      'Centralised guest management',
    ],
  },
  {
    name: 'Premium',
    prices: [[1, 499], [2, 476], [3, 475], [6, 450], [11, 425], [16, 375]],
    color: '#8fd0b8',
    description:
      'Powerful PMS and channel management software for professional managers',
    features: [
      'API channel manager',
      'Pricing & availability sync',
      'All major channels supported',
      'Manage from App & desktop',
      'Free onboarding support',
      'Import direct bookings',
    ],
  },
  {
    name: 'Professional',
    prices: [
      [1, 1499],
      [2, 1426],
      [3, 1425],
      [6, 1350],
      [11, 1275],
      [16, 1125],
    ],
    color: '#24a9e1',
    description: 'New features coming soon',
    features: [
      'Guest ID verification',
      'Capture guest details',
      'AI powered verification',
      'Integrated into check-in flow',
      'Toggle ID on/off per channel',
      'Dedicated account manager',
    ],
    buttonEmail: true,
  },
  {
    name: 'Business',
    prices: [
      [1, 1999],
      [2, 1901],
      [3, 1900],
      [6, 1800],
      [11, 1700],
      [16, 1500],
    ],
    color: '#ee4640',
    description: 'New features coming soon',
    features: [
      'Keyless intercom integrations',
      'Smartphone property access',
      'Communal & internal doors',
      'Fully automated 24/7 check-ins',
      'Biometric guest verification',
      'Selfie check on photo ID',
    ],
    buttonEmail: true,
  },
];

const PagePricing = ({
  data: { page, allDatoCmsPartner, faqConnection },
}: Props) => {
  if (!page) throw new Error('PagePricing missing from CMS');
  if (!allDatoCmsPartner) throw new Error('Missing allDatoCmsPartner');

  // const plans = unwrapConnection(plansConnection);
  const faq = unwrapConnection(faqConnection);

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
          {page.leadingText && <MarkdownPs html={page.leadingText} />}
          <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
        </StyledLeadingText>

        <Plans>
          {plans &&
            plans.map((plan, i) => (
              <PricingPlan
                key={plan.name}
                plan={plan}
                color={plan.color}
                featuresPlus={plans[i - 1]?.name}
              />
            ))}
        </Plans>
      </Section>
      <SectionPricingSlider plans={plans} />
      <SectionPricingEnterprise />
      <SectionPricingFeatures pricing={page} />
      <Section>
        <StyledHeader>
          <TextHeading level={3} tag="h2">
            Commonly asked questions
          </TextHeading>
        </StyledHeader>
      </Section>
      <Section>
        <Questions>
          {faq &&
            faq.map(
              question =>
                question.pricing && (
                  <QuestionAnswer key={question.id} faq={question} />
                )
            )}
        </Questions>
      </Section>
      <SectionsPartners partnersConnection={allDatoCmsPartner} />
    </Layout>
  );
};
export default PagePricing;

export const query = graphql`
  query PagePricingQuery {
    page: datoCmsPagePricing {
      heading
      leadingText
      buttonLabel
      buttonLink
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...SectionPricingFeatures_pricing
    }

    plansConnection: allDatoCmsPlan {
      edges {
        node {
          id
          ...PricingPlan_plan
        }
      }
    }

    faqConnection: allDatoCmsFaq {
      edges {
        node {
          id
          pricing
          ...QuestionAnswer_faq
        }
      }
    }

    allDatoCmsPartner {
      ...SectionsPartners_partnersConnection
    }
  }
`;

const Plans = styled('div')`
  display: flex;
  justify-content: center;
  padding-bottom: ${rem(Dimen.spacing * 2)};

  > div {
    margin: 0 ${rem(Dimen.spacing / 4)};
    width: 100%;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;

const Questions = styled('div')`
  padding-bottom: ${rem(Dimen.spacing * 2)};
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;

const StyledHeader = styled('div')`
  text-align: center;
`;
